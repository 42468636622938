import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core"
import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share"

type Props = {
  link: string
}

const useStyles = makeStyles(theme => ({
  items: {
    "& >button": {
      margin: "1em 0.5em 0 0.5em",
    },
  },
  icon: {
    margin: "0 auto",
  },
}))

export default (props: Props) => {
  const { link } = props
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item className={classes.items}>
        <TwitterShareButton url={link}>
          <TwitterIcon size={36} round={true} />
        </TwitterShareButton>
      </Grid>
      <Grid item className={classes.items}>
        <LinkedinShareButton url={link}>
          <LinkedinIcon size={36} round={true} />
        </LinkedinShareButton>
      </Grid>
      <Grid item className={classes.items}>
        <FacebookShareButton url={link}>
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
      </Grid>
      <Grid item className={classes.items}>
        <InstapaperShareButton url={link}>
          <InstapaperIcon size={36} round={true} />
        </InstapaperShareButton>
      </Grid>
    </Grid>
  )
}
