// @ts-check
import React from "react"
import { graphql } from "gatsby"
import MainLayout from "../components/layout/layout"
import {
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Theme,
  Container,
  makeStyles,
} from "@material-ui/core"
import Author from "./Author"
import SEO from "../components/seo"
import SocialShare from "../components/SocialShare"
import { GatsbyImage } from "gatsby-plugin-image"

type BlogPostProps = {} //  & PageProps<BlogPostQuery>

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#fff",
    // marginTop: "20px",
    // marginBottom: "30px",
    // padding: "0 10px",
  },
  heroImageContainer: {
    padding: 0,
  },
  content: {
    //
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      padding: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
    "& .tocTitle": {
      textAlign: "center",
      marginBottom: 0,
      maxWidth: "400px",
      backgroundColor: theme.palette.primary.dark,
      color: "#f5f5f5",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& .table-of-contents": {
      backgroundColor: "#F5F5F5",
      maxWidth: "400px",
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    "& .table-of-contents > ul > li > p": {
      marginTop: 0,
      marginBottom: "2px",
    },
    "& .table-of-contents > ul": {
      listStyleType: "none",
      paddingLeft: "15px",
    },
    "& .table-of-contents > ul > li > ul": {
      marginBottom: "8px",
      listStyleType: "none",
      paddingLeft: "16px",
    },
    "& .table-of-contents > ul > li > ul > li > a": {
      color: "#658DAE",
      fontSize: "0.8rem",
    },
    "& .table-of-contents > ul > li > ul > li > p > a": {
      color: "#658DAE",
      fontSize: "0.8rem",
    },
    "& .table-of-contents > ul > li > ul > li > p": {
      marginBottom: "2px",
    },
    "& .table-of-contents > ul > li > ul > li > ul": {
      marginBottom: "2px",
      listStyleType: "none",
      paddingLeft: "16px",
    },
    "& .table-of-contents > ul > li > ul > li > ul > li > a ": {
      color: "#658DAE",
      fontSize: "0.8rem",
    },
    "& thead": {
      textAlign: "left",
    },
    "& tr > th > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem",
    },
    "& tr > td": {
      paddingRight: "15px",
    },
    "& tr > th": {
      paddingRight: "15px",
    },
    "& tr > td > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.primary.dark,
    },
    "& ul": {
      listStyleType: "circle",
    },
    "& h2": {
      marginBottom: "0.25rem",
    },
    "& h3": {
      marginBottom: "0.25rem",
    },
    "& h4": {
      marginBottom: "0.15rem",
    },
    "& p": {
      marginTop: 0,
    },
  },
  title: {
    margin: "0",
    paddingTop: "1.5rem",
  },
  subTitle: {},
}))

const blog: React.FC<BlogPostProps> = ({
  data,
  pageContext: { pathname },
}: any) => {
  const { contentfulBlogPost: post } = data
  const classes = useStyles()

  return (
    <MainLayout dark>
      <Container className={classes.heroImageContainer}>
        <GatsbyImage
          image={post.heroImage.gatsbyImageData}
          alt={post.heroImage.description}
        />
      </Container>

      <Container className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          {post.title}
        </Typography>
        <Typography variant="subtitle2" className={classes.subTitle}>
          {post.language === "en" ? "by" : "von"} {post.author.name}
        </Typography>
        <SocialShare link={"https://simpleen.io/" + pathname} />

        <div
          dangerouslySetInnerHTML={{
            __html: post.content.childMarkdownRemark.html,
          }}
          className={classes.content}
        />

        {/*<Author author={post.author} language={post.language} />*/}
      </Container>

      <SEO
        blogPosting={{
          author: {
            name: post.author.name,
            twitter: post.author.twitter,
          },
          headline: post.title,
          datePublished: post.SEOPublishDate,
          dateModified: post.SEOUpdateDate,
          description: post.seoDescription, // post.previewText.childMarkdownRemark.excerpt,
          image: {
            title: post.seoImage.title,
            url: post.seoImage.fixed.src,
            width: post.seoImage.fixed.width,
            height: post.seoImage.fixed.height,
          },
          section: post.seoSection,
          tags: post.seoTags ? post.seoTags : [],
          publishingBlog: post.publishingBlog,
        }}
        general={{
          title: post.seoTitle,
          contentType: "article",
          description: post.previewText.childMarkdownRemark.excerpt,
          // language: (post.language as any) ? post.language : "en",
          path: pathname,
          image: post.seoImage,
        }}
      />
    </MainLayout>
  )
}

export default blog

export const blogPostQuery = graphql`
  query BlogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      seoSection
      seoTitle
      seoDescription
      language
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      heroImage {
        title
        description
        gatsbyImageData(
          layout: FULL_WIDTH
          width: 1280
          placeholder: BLURRED
          quality: 90
        )
      }
      seoImage {
        title
        description
        fixed(width: 800, height: 800, quality: 95) {
          ...GatsbyContentfulFixed
        }
      }
      previewText {
        childMarkdownRemark {
          excerpt(pruneLength: 300)
        }
      }
      publishingBlog

      SEOPublishDate: publishDate(formatString: "YYYY-MM-DD")
      SEOUpdateDate: updatedAt(formatString: "YYYY-MM-DD")
      author {
        name
        twitter
        image {
          title
          description
          fluid(maxHeight: 500) {
            ...GatsbyContentfulFluid
          }
        }
        intro {
          childMarkdownRemark {
            html
          }
        }
        introEnglish {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
